<template>
  <v-container fluid>
    <v-row>
      <v-subheader>Minha Empresa > Cancelar Conta </v-subheader>
      <v-card width="100%" class="k-body">
        <base-header-information
          :title="headerTitle"
          :description="headerDescription"
        >
        </base-header-information>
        <v-container fluid>
          <v-form ref="form" @submit="submit" v-show="!sent && sent !== null">
            <v-row>
              <v-col cols="12">
                <span>Descreva o motivo do cancelamento da conta</span>
                <v-textarea
                  v-model="description"
                  name="description"
                  required
                  :rules="rules.description"
                ></v-textarea>
              </v-col>
              <v-col cols="10">
                <v-btn
                  color="success"
                  class="white--text mr-5 mt-2"
                  type="submit"
                  :loading="loading"
                >
                  solicitar cancelamento
                </v-btn>
                <v-btn
                  color="grey lighten-1"
                  class="white--text mt-2"
                  @click="clear"
                >
                  cancelar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
          <v-row v-show="sent">
            <v-col cols="12">
              <h3 class="k-mti">Sua solicitação foi enviada com sucesso.</h3>
              <p
                >Em breve você receberá um email com a geração do seu ticket
                para o acompanhamento.</p
              >
            </v-col>
          </v-row>
          <v-row v-show="sent === null">
            <v-col cols="12">
              <v-progress-circular indeterminate></v-progress-circular>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import axios from '@plugins/axios'

export default {
  name: 'CancelarConta',
  data() {
    return {
      loading: false,
      sent: null,
      description: '',
      rules: {
        description: [
          (v) => !!v || 'Por favor, preencha o motivo do cancelamento.',
        ],
      },
      headerTitle: 'Cancelamento da Conta',
      headerDescription: `Ficamos tristes com o seu interesse em cancelar a sua conta. Existe algo
      que possamos fazer para reverter sua intenção? Caso já tenha tomado sua
      decisão, preencha o campo abaixo e descreva os motivos que o levaram a
      deixar o Simplificador.`,
    }
  },
  computed: {},
  methods: {
    clear() {
      this.$refs.form.reset()
    },
    submit(ev) {
      ev.preventDefault()

      if (!this.$refs.form.validate()) return false

      this.loading = true
      axios
        .post('account-cancel', { description: this.description })
        .then((res) => {
          this.sent = true
          this.loading = false
        })
    },
  },
  mounted() {
    this.loading = true
    axios.get('account-cancel').then((res) => {
      this.sent = res.cancelled
      this.loading = false
    })
  },
}
</script>
<style lang="sass" scoped>

.k-clist
  margin-top: 0
  padding-top: 0 !important

.k-nav
  font-size: 20px
  margin-left: 25px

  a
    text-decoration: none
    cursor: pointer

.k-list
  ul
    list-style-type: none
    padding-left: 0px

    li
      min-height: 40px
      padding: 10px 10px 10px 20px
      border-bottom: 1px gray solid
      cursor: pointer

      i
        margin-right: 10px

  .k-folder
    .k-loading-line
      margin: 0
    li
      &:hover
        background: #e8f5e9
</style>
